import React from 'react';

import {
  ArticleBlock,
  ArticleBlockCategory,
  ArticleBlockImage,
  ArticleBlockSummary,
  ArticleBlockTitle,
  AuthorBlock,
} from '~/components/the-shed/components';
import { shedArticleRoute } from '~/routes';

import styles from '~/components/the-shed/components/article-grid-trio.module.scss';

const ArticleGridTrio = ({ articles }) => {
  return (
    <>
      {!articles || articles.length === 0 ? (
        <EmptyGrid />
      ) : (
        <div className={styles.articleGrid}>
          {articles.map((article) => (
            <ArticleGridItem article={article} key={article.slug} />
          ))}
        </div>
      )}
    </>
  );
};

export { ArticleGridTrio };

const EmptyGrid = () => {
  return (
    <div className={styles.emptyGrid}>
      <p>No articles to display.</p>
    </div>
  );
};

const ArticleGridItem = ({ article }) => {
  const {
    title,
    shortDescription,
    category,
    slug,
    thumbnailImage,
    thumbnailImagePreview,
    author,
    publishedAt,
    updatedAt,
  } = article;

  const articleRoute = shedArticleRoute({
    category: category?.articleCategory?.slug,
    slug,
  });

  return (
    <ArticleBlock className={styles.articleBlock}>
      <ArticleBlockImage
        className={styles.articleBlockImage}
        image={thumbnailImage}
        imagePreview={thumbnailImagePreview}
        to={articleRoute}
      />
      <ArticleBlockCategory
        category={category}
        className={styles.articleBlockCategory}
      />
      <ArticleBlockTitle
        className={styles.articleBlockTitle}
        title={title}
        to={articleRoute}
      />
      <AuthorBlock
        author={author}
        className={styles.authorBlock}
        publishedAt={publishedAt}
        updatedAt={updatedAt}
        variant="horizontal-simple"
      />
      <ArticleBlockSummary
        className={styles.articleBlockSummary}
        summary={shortDescription}
      />
    </ArticleBlock>
  );
};
