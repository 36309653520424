import React from 'react';

import {
  ArticleBlock,
  AuthorBlock,
  ArticleBlockImage,
  ArticleBlockCategory,
  ArticleBlockTitle,
  DashHeader,
} from '~/components/the-shed/components';
import { shedArticleRoute } from '~/routes';

import styles from '~/components/the-shed/components/article-quartet.module.scss';

const ArticleQuartet = ({ title, articles = [] }) => (
  <section>
    <div className={styles.quartetHeader}>
      <DashHeader title={title} />
    </div>
    <div className={styles.quartetArticles}>
      {articles.map((article) => {
        const {
          title,
          category,
          thumbnailImage,
          thumbnailImagePreview,
          author,
          publishedAt,
          slug,
          updatedAt,
        } = article;

        return (
          <ArticleBlock key={title}>
            <ArticleBlockImage
              className={styles.articleBlockImage}
              image={thumbnailImage}
              imagePreview={thumbnailImagePreview}
              to={shedArticleRoute({
                category: category?.articleCategory?.slug,
                slug,
              })}
            />
            <ArticleBlockCategory
              category={category}
              className={styles.category}
            />
            <ArticleBlockTitle
              title={title}
              to={shedArticleRoute({
                category: category?.articleCategory?.slug,
                slug,
              })}
            />
            <span className={styles.authorBlock}>
              <AuthorBlock
                author={author}
                publishedAt={publishedAt}
                updatedAt={updatedAt}
                variant="horizontal-simple"
              />
            </span>
          </ArticleBlock>
        );
      })}
    </div>
  </section>
);

export { ArticleQuartet };
